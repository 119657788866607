import React from 'react';
import { createRoot } from 'react-dom/client';
import {
	BrowserRouter as Router,
	Routes,
	Route
} from "react-router-dom";
import Lpr from './views/Lpr';
import Discuss from './views/Discuss';

if (window.location.hostname === "localhost") {
	window.MyDataHelps.setParticipantAccessToken({ "access_token": process.env.REACT_APP_PARTICIPANT_ACCESS_TOKEN, "expires_in": 21600, "token_type": "Bearer" }, `${process.env.REACT_APP_MDH_BASE_URL}/`);
}

const container = document.getElementById('root')
const root = createRoot(container);
root.render(
	<React.StrictMode>
		<Router>
			<Routes>
				<Route path="/lpr" element={<Lpr />} />
				<Route path="/discuss" element={<Discuss />} />
			</Routes>
		</Router>
	</React.StrictMode>,
);