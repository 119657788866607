import { TextBlock } from "@careevolution/mydatahelps-ui";
import React from "react";
import { MeasureReportProps, characterizationAsElement } from "./MeasureReport";
import earned from "../../images/cms130v10/earned.webp";
import unearned from "../../images/cms130v10/unearned.webp";
import "./MeasureReport.css";

const description = "Colorectal Cancer Screening";

export default function CMS130V10(props: MeasureReportProps) {
  return (
    <>
      {characterizationAsElement(
        props.coding.characterization,
        earned,
        (<TextBlock>Congratulations! You've earned the {description} achievement for {props.period.description}!</TextBlock>),
        unearned,
        (
          <>
            <TextBlock>You haven't yet earned the {description} achievement for {props.period.description}, though you may be eligible.</TextBlock>
            <TextBlock>Talk with your healthcare provider to ensure your colon cancer screenings are up to date.</TextBlock>
          </>
        ),
      )}
      <TextBlock>{description} is essential to detect and prevent colorectal cancer, the third most common cancer worldwide.</TextBlock>
    </>
  );
}
