import MyDataHelps from "@careevolution/mydatahelps-js";
import { ConsumeBundleRequest } from "@shared/ConsumeBundleRequest";
import { ConsumeResponse } from "@shared/ConsumeResponse";

const consumeBundleUrl = process.env.REACT_APP_CONSUME_BUNDLE_URL || "";

export async function consumeBundle<ConsumingType extends ConsumeResponse>(request: ConsumeBundleRequest): Promise<ConsumingType> {
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${MyDataHelps.token.access_token}`);
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");

  const response = await fetch(consumeBundleUrl, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(request),
    keepalive: true,
  });
  return await response.json() as ConsumingType;
}