import { Grid } from "@mui/material";
import "./MeasureReport.css";
import React from "react";
import { MeasureReport, MeasureReportGroup } from "fhir/r4";

interface MeasureReportGroupPopulation {
  population: MeasureReportGroup[];
}

export interface MeasureReportCoding {
  name: string;
  initialPopulation: number | null;
  denominator: number | null;
  denominatorExclusion: number | null;
  numerator: number | null;
  characterization: MeasureReportCharacterization | null;
}

function getPopulationAttribute(measureReport: MeasureReport, attribute: string): any | null {
  if (!measureReport.group || measureReport.group.length > 1) {
    console.error("Expected exactly one group in measure report.");
    return null;
  }
  const group = measureReport.group[0] as MeasureReportGroupPopulation;
  const attributeValue = group.population.find((population) => population.code?.coding?.[0].code === attribute) as any;
  return attributeValue?.count ?? null;
}

type MeasureReportCharacterization = "met" | "not-met";

function characterizeReport(coding: MeasureReportCoding): MeasureReportCharacterization {
  if (coding.numerator === 1) {
    return "met";
  }
  return "not-met";
}

export function getCoding(measureReport: MeasureReport): MeasureReportCoding {
  const coding = {
    name: measureReport.measure.replace("Measure/", ""),
    initialPopulation: getPopulationAttribute(measureReport, "initial-population"),
    denominator: getPopulationAttribute(measureReport, "denominator"),
    denominatorExclusion: getPopulationAttribute(measureReport, "denominator-exclusion"),
    numerator: getPopulationAttribute(measureReport, "numerator"),
  } as MeasureReportCoding;
  return {
    ...coding,
    ...{ characterization: characterizeReport(coding) }
  };
}

export function characterizationAsElement(
  characterization: MeasureReportCharacterization | null,
  earned: string,
  earnedDescription: React.ReactNode,
  unearned: string,
  unearnedDescription: React.ReactNode,
): React.ReactElement {
  if (!characterization) {
    return <></>;
  }
  if (characterization === "met") {
    return (
      <Grid container spacing={2} >
        <Grid item xs={4} >
          <picture>
            <source srcSet={earned} type="image/webp" />
          </picture>
          < img src={earned} className="badge" />
        </Grid>
        <Grid item xs={8} >
          {earnedDescription}
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container spacing={2} >
      <Grid item xs={4} >
        <picture>
          <source srcSet={unearned} type="image/webp" />
        </picture>
        <img src={unearned} className="badge" />
      </Grid>
      <Grid item xs={8} >
        {unearnedDescription}
      </Grid>
    </Grid>
  );
};

export interface MeasureReportProps {
  coding: MeasureReportCoding;
  period: MeasurePeriod;
}

export type MeasurePeriodKey = "current" | "year1" | "year2";

export class MeasurePeriod {
  description: string;
  title: string;

  constructor(public key: MeasurePeriodKey) {
    this.description = getPeriodDescription(key);
    this.title = getPeriodTitle(key);
  }
}

function getPeriodDescription(period: MeasurePeriodKey): string {
  const now = new Date();
  const currentYear = now.getFullYear();
  switch (period) {
    case "current":
      return "the current year";
    case "year1":
      return `year ${currentYear - 1}`;
    case "year2":
      return `year ${currentYear - 2}`;
  }
}

function getPeriodTitle(period: MeasurePeriodKey): string {
  const now = new Date();
  const currentYear = now.getFullYear();
  switch (period) {
    case "current":
      return "Current";
    case "year1":
      return `${currentYear - 1}`;
    case "year2":
      return `${currentYear - 2}`;
  }
}