import { TextBlock } from "@careevolution/mydatahelps-ui";
import React from "react";
import { MeasureReportProps, characterizationAsElement } from "./MeasureReport";
import earned from "../../images/cms165v10/earned.webp";
import unearned from "../../images/cms165v10/unearned.webp";
import "./MeasureReport.css";

const description = "Controlling High Blood Pressure";

export default function CMS165V10(props: MeasureReportProps) {
  return (
    <>
      {characterizationAsElement(
        props.coding.characterization,
        earned,
        (<TextBlock>Congratulations! You've earned the {description} achievement for {props.period.description}!</TextBlock>),
        unearned,
        (
          <>
            <TextBlock>You haven't yet earned the {description} achievement for {props.period.description}, though you may be eligible.</TextBlock>
            <TextBlock>Talk with your healthcare provider to ensure you're doing everything you can to manage your blood pressure.</TextBlock>
          </>
        ),
      )}
      <TextBlock>The goal of {description} is to promote better health outcomes by ensuring that patients with hypertension receive appropriate treatment and achieve optimal blood pressure control. High blood pressure is a significant risk factor for cardiovascular diseases, such as heart attacks and strokes, so effective control of blood pressure is crucial for reducing these risks.</TextBlock>
    </>
  );
}
