import { Action, Card, CardTitle } from '@careevolution/mydatahelps-ui';
import React from 'react';
import { DownloadResponse } from "@shared/DownloadInterface";
import { consumeBundle } from './Consume';


interface DownloadBundleProps {
  bundleIdentifier: string | undefined;
}

export default function Download(props: DownloadBundleProps): React.ReactElement {
  async function fetchDownloadUrl(): Promise<URL> {
    if (!props.bundleIdentifier) {
      throw new Error("No bundle identifier provided");
    }

    const downloadResponse = await consumeBundle<DownloadResponse>({ purpose: "download", identifier: props.bundleIdentifier });
    return new URL(downloadResponse.url);
  }

  async function sendBundleToClient(): Promise<void> {
    if (!props.bundleIdentifier) {
      return;
    }
    const url = await fetchDownloadUrl();
    const link = document.createElement("a");
    link.href = url.href;
    link.download = `${props.bundleIdentifier}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <Card>
      <CardTitle title='Download LPR' />
      <Action
        subtitle={'Get your longitudinal patient record as a FHIR Bundle.'}
        onClick={sendBundleToClient} />
    </Card>
  );
}