import { TextBlock } from "@careevolution/mydatahelps-ui";
import React from "react";
import { MeasureReportProps, characterizationAsElement } from "./MeasureReport";
import earned from "../../images/cms122v10/earned.webp";
import unearned from "../../images/cms122v10/unearned.webp";
import "./MeasureReport.css";

const description = "Diabetes: Hemoglobin A1c (HbA1c) Control";

export default function CMS122V10(props: MeasureReportProps) {
  return (
    <>
      {characterizationAsElement(
        props.coding.characterization,
        earned,
        (<TextBlock>Congratulations! You've earned the {description} achievement for {props.period.description}!</TextBlock>),
        unearned,
        (
          <>
            <TextBlock>You haven't yet earned the {description} achievement for {props.period.description}, though you may be eligible.</TextBlock>
            <TextBlock>Talk with your healthcare provider to ensure you're doing everything you can to keep your blood sugar managed.</TextBlock>
          </>
        ),
      )}
      <TextBlock>{description} focuses on evaluating whether patients with diabetes have achieved HbA1c levels above a specific threshold, indicating possible poor blood sugar control. Effective control of HbA1c levels is essential for managing diabetes and reducing the risk of complications associated with the disease.</TextBlock>
    </>
  );
}
