import MyDataHelps from '@careevolution/mydatahelps-js';
import { LoadingIndicator, TextBlock } from '@careevolution/mydatahelps-ui';
import React, { useEffect, useState } from 'react';
import "./Compile.css";
import { RequestBundleResponse } from '@shared/RequestBundleResponse';

interface CompileBundleProps {
  loadingText?: string;
  children?: React.ReactNode;
}

const fetchUrl = process.env.REACT_APP_FETCH_BUNDLE_URL || "";

async function fetchBundleIdentifier(): Promise<string> {
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${MyDataHelps.token.access_token}`);
  headers.append("Accept", "application/json");

  const response = await fetch(fetchUrl, {
    method: "GET",
    headers: headers,
  });
  const bundleResponse: RequestBundleResponse = await response.json();
  return bundleResponse.identifier;
}

export default function Compile(props: CompileBundleProps) {
  const [bundleIdentifier, setBundleIdentifier] = useState<any>(null);
  const [loadingBundle, setLoadingBundle] = useState<boolean>(true);

  useEffect(() => {
    const init = async () => await initialize();
    init();
    MyDataHelps.on("applicationDidBecomeVisible", init);
    return () => {
      MyDataHelps.off("applicationDidBecomeVisible", init);
    };
  }, []);

  async function initialize() {
    try {
      await MyDataHelps.connect();
      const bundleIdentifier = await fetchBundleIdentifier();
      setBundleIdentifier(bundleIdentifier);
    } finally {
      setLoadingBundle(false);
    }
  }

  function buildChildren(): React.ReactNode {
    return React.Children.map(props.children, (child: React.ReactNode) => React.cloneElement(child as React.ReactElement<any>, { bundleIdentifier: bundleIdentifier }));
  }

  return (
    <>
      {loadingBundle &&
        <>
          <TextBlock className="compileBundle">{props.loadingText ?? ""}</TextBlock>
          <LoadingIndicator />
        </>
      }
      {!loadingBundle && bundleIdentifier && buildChildren()}
    </>
  );
}
