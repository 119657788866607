import { TextBlock } from "@careevolution/mydatahelps-ui";
import React from "react";
import { MeasureReportProps, MeasureReportCoding, characterizationAsElement } from "./MeasureReport";
import earned from "../../images/cms074v10/earned.webp";
import unearned from "../../images/cms074v10/unearned.webp";
import "./MeasureReport.css";

const description = "Primary Caries Prevention";

export default function CMS074V10(props: MeasureReportProps) {
  return (
    <>
      {characterizationAsElement(
        props.coding.characterization,
        earned,
        (<TextBlock>Congratulations! You've earned the {description} achievement for {props.period.description}!</TextBlock>),
        unearned,
        (
          <>
            <TextBlock>You haven't yet earned the {description} achievement for {props.period.description}, though you may be eligible.</TextBlock>
            <TextBlock>Talk with your healthcare provider to ensure your dental care is up to date.</TextBlock>
          </>
        ),
      )}
      <TextBlock>{description} is designed to help patients maintain optimal oral health and prevent the development of dental caries, commonly known as cavities. By implementing preventive measures, individuals can significantly reduce their risk of tooth decay and the need for extensive dental treatments.</TextBlock>
    </>
  );
}
