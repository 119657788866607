import React from 'react';
import { Layout, ProjectHeader } from "@careevolution/mydatahelps-ui";
import Compile from '../components/Compile';
import Download from '../components/Lpr/Download';
import RiskProfile from '../components/Lpr/RiskProfile';
import Measures from '../components/Measures/Measures';
import "./Lpr.css";

export default function Lpr() {
  return (
    <Layout bodyBackgroundColor='#FFFFFF'>
      <Compile loadingText="Evaluating your healthcare achievements">
        {/* <RiskProfile key="RiskProfile" bundleIdentifier={undefined} /> */}
        <Measures key="Measures" bundleIdentifier={undefined} />
        <Download key="Download" bundleIdentifier={undefined} />
      </Compile>
    </Layout>
  );
}