import { TextBlock } from "@careevolution/mydatahelps-ui";
import React from "react";
import { MeasureReportProps, characterizationAsElement } from "./MeasureReport";
import earned from "../../images/cms104v10/earned.webp";
import unearned from "../../images/cms104v10/unearned.webp";
import "./MeasureReport.css";

const description = "Antithrombotic Therapy";

export default function CMS104V10(props: MeasureReportProps) {
  return (
    <>
      {characterizationAsElement(
        props.coding.characterization,
        earned,
        (<TextBlock>Congratulations! You've earned the {description} achievement for {props.period.description}!</TextBlock>),
        unearned,
        (
          <>
            <TextBlock>You haven't yet earned the {description} achievement for {props.period.description}, though you may be eligible.</TextBlock>
            <TextBlock>Talk with your healthcare provider about any recent blood clotting therapies to ensure you're up to date.</TextBlock>
          </>
        ),
      )}
      <TextBlock>{description} is designed to assess whether patients who have been diagnosed with specific medical conditions are appropriately discharged from a healthcare facility with medications that help prevent blood clots.</TextBlock>
    </>
  );
}
