import React, { useEffect, useState } from "react";
import { DiscussRequest, DiscussResponse } from "@shared/DiscussInterface";
import MyDataHelps from "@careevolution/mydatahelps-js";
import { Button, Card, LoadingIndicator } from "@careevolution/mydatahelps-ui";
import { consumeBundle } from "../Lpr/Consume";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  MessageModel,
  Loader,
} from "@chatscope/chat-ui-kit-react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import "./Conversation.css";

interface ConversationProps {
  bundleIdentifier: string | undefined;
}

interface ConversationReference {
  id: string;
  verification: string;
}

interface ConversationMessage {
  role: "user" | "assistant";
  text: string;
}

interface ConversationState {
  conversation: ConversationReference | null;
  messages: ConversationMessage[];
}



export default function Conversation(props: ConversationProps): React.ReactElement {
  const [loading, setLoading] = useState<boolean>(true);
  const [conversation, setConversation] = useState<ConversationState>({
    conversation: null,
    messages: [],
  });

  const stateAsRequest = () => {
    if (!props.bundleIdentifier) {
      throw new Error("No bundle identifier");
    }
    const lastMessage = conversation.messages[conversation.messages.length - 1];
    return {
      purpose: "discuss",
      identifier: props.bundleIdentifier,
      conversation: conversation.conversation,
      message: lastMessage.text,
    } as DiscussRequest;
  };

  useEffect(() => {
    const init = async () => await initialize();
    init();
    MyDataHelps.on("applicationDidBecomeVisible", init);
    return () => {
      MyDataHelps.off("applicationDidBecomeVisible", init);
    };
  }, []);

  useEffect(() => {
    const lastMessage = conversation.messages[conversation.messages.length - 1];
    if (!lastMessage) {
      return;
    }
    if (lastMessage.role === "user") {
      const request = stateAsRequest();
      const getAssistantResponse = async () => await consumeBundle<DiscussResponse>(request);
      getAssistantResponse().then((response) => {
        const newMessage = {
          role: "assistant",
          text: response.message,
        } as ConversationMessage;
        setConversation({
          conversation: response.conversation,
          messages: [...conversation.messages, newMessage],
        });
      });
    }
    if (lastMessage.role === "assistant") {
      MyDataHelps.getParticipantInfo().then((participantInfo) => {
        console.log({ conversation: conversation.conversation, pptId: participantInfo.participantID });
      });
    }
  }, [conversation]);

  const sendUserMessage = async (message: string) => {
    const newMessage = {
      role: "user",
      text: message,
    } as ConversationMessage;
    setConversation({
      conversation: conversation.conversation,
      messages: [...conversation.messages, newMessage],
    });
  };

  async function initialize() {
    try {
      setLoading(true);
    } finally {
      setLoading(false);
    }
  }

  function renderMessages() {
    const displayMessages = conversation.messages.map((message, index) => {
      const direction = message.role === "user" ? "outgoing" : "incoming";
      const model = {
        message: message.text,
        sender: message.role,
        direction: direction,
      } as MessageModel;
      return <Message key={index} model={model} />;
    });
    const lastMessage = conversation.messages[conversation.messages.length - 1];
    if (lastMessage && lastMessage.role === "user") {
      displayMessages.push(<Loader />);
    }
    return displayMessages;
  }

  function handleUserMesssage(innerHtml: string, textContent: string, innerText: string, nodes: NodeList) {
    sendUserMessage(textContent);
  }

  function renderInput() {
    const hasMessages = conversation.messages.length > 0;
    if (!hasMessages) {
      return <MessageInput placeholder="Ask a question of your compiled health record." onSend={handleUserMesssage} />;
    }

    return <MessageInput onSend={handleUserMesssage} />;
  }

  function restartConversation() {
    setConversation({
      conversation: null,
      messages: [],
    });
  }

  function displayDiscussion() {
    return (
      <>
        <div className="messagePane">
          <MainContainer>
            <ChatContainer>
              <MessageList>
                {renderMessages()}
              </MessageList>
              {renderInput()}
            </ChatContainer>
          </MainContainer>
        </div>
      </>
    );
  }

  function render() {
    if (loading) {
      return (
        <Card >
          <LoadingIndicator />
        </Card>
      );
    }
    return (
      <>
        <Card>
          {displayDiscussion()}
        </Card>
        <Card className="bottomChild">
          <Button onClick={restartConversation}>Restart</Button>
        </Card>
      </>
    );
  }

  return render();
};