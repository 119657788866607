import MyDataHelps from '@careevolution/mydatahelps-js';
import { Card, CardTitle, LoadingIndicator, SegmentedControl, TextBlock } from '@careevolution/mydatahelps-ui';
import { Bundle, MeasureReport } from 'fhir/r4';
import { Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { consumeBundle } from '../Lpr/Consume';
import { MeasureResponse } from '@shared/MeasureInterface';
import { MeasureReportCoding, getCoding, MeasureReportProps, MeasurePeriod, MeasurePeriodKey } from './MeasureReport';
import CMS074V10 from "./CMS074V10";
import CMS104V10 from "./CMS104V10";
import CMS122V10 from "./CMS122V10";
import CMS124V10 from "./CMS124V10";
import CMS125V10 from "./CMS125V10";
import CMS130V10 from "./CMS130V10";
import CMS153V10 from "./CMS153V10";
import CMS165V10 from "./CMS165V10";
import "./Measures.css";

interface MeasuresProps {
  bundleIdentifier: string | undefined;
}

interface MeasuresBundle extends MeasureResponse {
  bundle: Bundle;
}

const disclaimer = (
  <TextBlock>
    <em>Note:</em> The information provided here is not intended to replace the advice of a medical professional. Please consult your healthcare provider for personalized medical advice.
  </TextBlock>
);
const defaultResponse = (
  <>
    <TextBlock>There are currently no achievements on your record.</TextBlock>
    <Divider variant="middle" />
    {disclaimer}
  </>
);



export default function Measures(props: MeasuresProps): React.ReactElement {
  const [loading, setLoading] = useState<boolean>(false);
  const [measures, setMeasures] = useState<Bundle | null>(null);
  const [period, setPeriod] = useState<MeasurePeriod>(new MeasurePeriod("current"));

  useEffect(() => {
    const init = async () => await initialize();
    init();
    MyDataHelps.on("applicationDidBecomeVisible", init);
    return () => {
      MyDataHelps.off("applicationDidBecomeVisible", init);
    };
  }, []);

  async function fetchMeasures(): Promise<Bundle> {
    if (!props.bundleIdentifier) {
      throw new Error("No bundle identifier provided.");
    }
    const measures = await consumeBundle<MeasuresBundle>({ purpose: "measure", identifier: props.bundleIdentifier });
    return measures.bundle;
  }

  async function initialize() {
    try {
      setLoading(true);
      const measures = await fetchMeasures();
      setMeasures(measures);
    } finally {
      setLoading(false);
    }
  }

  function mapMeasureToCoding(measure: MeasureReport): MeasureReportCoding | null {
    if (!measure.measure) {
      return null;
    }
    if (measure.status !== "complete") {
      return null;
    }

    const coding = getCoding(measure);
    if (coding.denominator === 0) {
      return null;
    }
    // TODO: Remove this after dev
    if (coding.name.startsWith("CMS130V")) {
      coding.characterization = "met";
    }

    return coding;
  }

  function mapCodingToComponent(measure: MeasureReportCoding | null): React.ReactNode | null {
    if (!measure) {
      return null;
    }

    switch (measure.name) {
      case "CMS074V10":
        return <CMS074V10 key={measure.name} coding={measure} period={period} />;
      case "CMS104V10":
        return <CMS104V10 key={measure.name} coding={measure} period={period} />;
      case "CMS122V10":
        return <CMS122V10 key={measure.name} coding={measure} period={period} />;
      case "CMS124V10":
        return <CMS124V10 key={measure.name} coding={measure} period={period} />;
      case "CMS125V10":
        return <CMS125V10 key={measure.name} coding={measure} period={period} />;
      case "CMS130V10":
        return <CMS130V10 key={measure.name} coding={measure} period={period} />;
      case "CMS153V10":
        return <CMS153V10 key={measure.name} coding={measure} period={period} />;
      case "CMS165V10":
        return <CMS165V10 key={measure.name} coding={measure} period={period} />;
      default:
        return null;
    }
  }

  function periodSelector(): React.ReactNode {
    const measureReports = ([
      new MeasurePeriod("year2"),
      new MeasurePeriod("year1"),
      new MeasurePeriod("current")
    ]).map((measurePeriod) => {
      return { title: measurePeriod.title, key: measurePeriod.key };
    });
    return (
      <SegmentedControl
        className="periodControl"
        color="#EF9749"
        segments={measureReports}
        selectedSegment={period.key}
        onSegmentSelected={(value: MeasurePeriodKey) => setPeriod(new MeasurePeriod(value))}
      />
    );
  }

  function renderMeasures(): React.ReactNode {
    if (!measures) {
      return <>{periodSelector()}{defaultResponse}</>;
    }

    const measureEntries = measures.entry?.filter((entry) => entry.resource?.resourceType === "MeasureReport");
    const measureReports = measureEntries?.map((entry) => entry.resource as MeasureReport);
    if (!measureReports || measureReports.length === 0) {
      return <>{periodSelector()}{defaultResponse}</>;
    }
    const measureComponents = measureReports
      .map(mapMeasureToCoding)
      .sort((first, second) => {
        if (!first) {
          return 1;
        }
        if (!second) {
          return -1;
        }
        if (first.characterization === "met" && second.characterization !== "met") {
          return -1;
        }
        if (first.characterization !== "met" && second.characterization === "met") {
          return 1;
        }
        return 0;
      })
      .map(mapCodingToComponent)
      .filter((component) => component !== null);
    if (measureComponents.length === 0) {
      return <>{periodSelector()}{defaultResponse}</>;
    }
    const dividedComponents = measureComponents.map((component, index) => (
      <React.Fragment>
        {component}
        {index < measureComponents.length - 1 && <Divider variant="middle" />}
      </React.Fragment>
    ));
    return <>
      {periodSelector()}
      {dividedComponents}
      <Divider variant="middle" />
      {disclaimer}
    </>;

  }

  return (
    <Card>
      <CardTitle title="Health Achievement Badges" />
      {loading &&
        <LoadingIndicator />
      }
      {!loading && renderMeasures()}
    </Card>
  );
};