import React from 'react';
import { Layout } from "@careevolution/mydatahelps-ui";
import Compile from '../components/Compile';
import "./Lpr.css";
import Conversation from '../components/Discuss/Conversation';

export default function Discuss() {
  return (
    <Layout bodyBackgroundColor='#FFFFFF'>
      <Compile loadingText="Compiling your personal health record">
        <Conversation key="Conversation" bundleIdentifier={undefined} />
      </Compile>
    </Layout>
  );
}